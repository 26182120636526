import { doesIdentityRequireRefresh, isIdentityExpired, memoizeIdentityProvider, } from "@smithy/core";
import { getSmithyContext } from "@smithy/util-middleware";
export const defaultBaptizeAmericaHttpAuthSchemeParametersProvider = async (config, context, input) => {
    return {
        operation: getSmithyContext(context).operation,
    };
};
function createAwsAuthCognitoUserPoolsHttpAuthOption(authParameters) {
    return {
        schemeId: "aws.auth#cognitoUserPools",
    };
}
;
function createSmithyApiHttpBearerAuthHttpAuthOption(authParameters) {
    return {
        schemeId: "smithy.api#httpBearerAuth",
        propertiesExtractor: ({ profile, filepath, configFilepath, ignoreCache, }, context) => ({
            identityProperties: {
                profile,
                filepath,
                configFilepath,
                ignoreCache,
            },
        }),
    };
}
;
function createSmithyApiNoAuthHttpAuthOption(authParameters) {
    return {
        schemeId: "smithy.api#noAuth",
    };
}
;
export const defaultBaptizeAmericaHttpAuthSchemeProvider = (authParameters) => {
    const options = [];
    switch (authParameters.operation) {
        case "Info":
            {
                options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
                break;
            }
            ;
        default:
            {
                options.push(createAwsAuthCognitoUserPoolsHttpAuthOption(authParameters));
                options.push(createSmithyApiHttpBearerAuthHttpAuthOption(authParameters));
            }
            ;
    }
    ;
    return options;
};
export const resolveHttpAuthSchemeConfig = (config) => {
    const token = memoizeIdentityProvider(config.token, isIdentityExpired, doesIdentityRequireRefresh);
    return {
        ...config,
        token,
    };
};
