import { defaultBaptizeAmericaHttpAuthSchemeProvider } from "./auth/httpAuthSchemeProvider";
import { defaultRegionInfoProvider } from "./endpoints";
import { HttpBearerAuthSigner, NoAuthSigner, } from "@smithy/core";
import { NoOpLogger } from "@smithy/smithy-client";
import { parseUrl } from "@smithy/url-parser";
import { fromBase64, toBase64, } from "@smithy/util-base64";
import { fromUtf8, toUtf8, } from "@smithy/util-utf8";
export const getRuntimeConfig = (config) => {
    return {
        apiVersion: "1.0",
        base64Decoder: config?.base64Decoder ?? fromBase64,
        base64Encoder: config?.base64Encoder ?? toBase64,
        disableHostPrefix: config?.disableHostPrefix ?? false,
        extensions: config?.extensions ?? [],
        httpAuthSchemeProvider: config?.httpAuthSchemeProvider ?? defaultBaptizeAmericaHttpAuthSchemeProvider,
        httpAuthSchemes: config?.httpAuthSchemes ?? [{
                schemeId: "smithy.api#httpBearerAuth",
                identityProvider: (ipc) => ipc.getIdentityProvider("smithy.api#httpBearerAuth"),
                signer: new HttpBearerAuthSigner(),
            }, {
                schemeId: "smithy.api#noAuth",
                identityProvider: (ipc) => ipc.getIdentityProvider("smithy.api#noAuth") || (async () => ({})),
                signer: new NoAuthSigner(),
            }],
        logger: config?.logger ?? new NoOpLogger(),
        regionInfoProvider: config?.regionInfoProvider ?? defaultRegionInfoProvider,
        serviceId: config?.serviceId ?? "BaptizeAmerica",
        urlParser: config?.urlParser ?? parseUrl,
        utf8Decoder: config?.utf8Decoder ?? fromUtf8,
        utf8Encoder: config?.utf8Encoder ?? toUtf8,
    };
};
