import { BaptizeAmericaClient, } from "./BaptizeAmericaClient";
import { InfoCommand, } from "./commands/InfoCommand";
import { SayHelloCommand, } from "./commands/SayHelloCommand";
import { createAggregatedClient } from "@smithy/smithy-client";
const commands = {
    InfoCommand,
    SayHelloCommand,
};
export class BaptizeAmerica extends BaptizeAmericaClient {
}
createAggregatedClient(commands, BaptizeAmerica);
