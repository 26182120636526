import { BaptizeAmericaServiceException as __BaseException } from "./BaptizeAmericaServiceException";
export class ApiError extends __BaseException {
    constructor(opts) {
        super({
            name: "ApiError",
            $fault: "client",
            ...opts
        });
        this.name = "ApiError";
        this.$fault = "client";
        Object.setPrototypeOf(this, ApiError.prototype);
        this.errorMessage = opts.errorMessage;
    }
}
